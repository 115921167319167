var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "demo-card-analytics" } }, [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/3 mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Sessions By Device" } },
            [
              _c(
                "template",
                { slot: "actions" },
                [_c("change-time-duration-dropdown")],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "no-body" }, slot: "no-body" },
                [
                  _c("vue-apex-charts", {
                    staticClass: "mb-12 mt-4",
                    attrs: {
                      type: "donut",
                      height: "340",
                      options:
                        _vm.analyticsData.sessionsByDeviceDonut.chartOptions,
                      series: _vm.sessionsData.series,
                    },
                  }),
                ],
                1
              ),
              _c(
                "ul",
                _vm._l(_vm.sessionsData.analyticsData, function (deviceData) {
                  return _c(
                    "li",
                    { key: deviceData.device, staticClass: "flex mb-3" },
                    [
                      _c("feather-icon", {
                        attrs: {
                          icon: deviceData.icon,
                          svgClasses: [
                            `h-5 w-5 stroke-current text-${deviceData.color}`,
                          ],
                        },
                      }),
                      _c(
                        "span",
                        { staticClass: "ml-2 inline-block font-semibold" },
                        [_vm._v(_vm._s(deviceData.device))]
                      ),
                      _c("span", { staticClass: "mx-2" }, [_vm._v("-")]),
                      _c("span", { staticClass: "mr-4" }, [
                        _vm._v(_vm._s(deviceData.sessionsPercentage) + "%"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "ml-auto flex -mr-1" },
                        [
                          _c("span", { staticClass: "mr-1" }, [
                            _vm._v(
                              _vm._s(deviceData.comparedResultPercentage) + "%"
                            ),
                          ]),
                          _c("feather-icon", {
                            attrs: {
                              icon:
                                deviceData.comparedResultPercentage < 0
                                  ? "ArrowDownIcon"
                                  : "ArrowUpIcon",
                              svgClasses: [
                                deviceData.comparedResultPercentage < 0
                                  ? "text-danger"
                                  : "text-success",
                                "stroke-current h-4 w-4 mb-1 mr-1",
                              ],
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/3 mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Product Orders" } },
            [
              _c(
                "template",
                { slot: "actions" },
                [_c("change-time-duration-dropdown")],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "no-body" }, slot: "no-body" },
                [
                  _c("vue-apex-charts", {
                    attrs: {
                      type: "radialBar",
                      height: "420",
                      options:
                        _vm.analyticsData.productOrdersRadialBar.chartOptions,
                      series: _vm.productsOrder.series,
                    },
                  }),
                ],
                1
              ),
              _c(
                "ul",
                _vm._l(_vm.productsOrder.analyticsData, function (orderData) {
                  return _c(
                    "li",
                    {
                      key: orderData.orderType,
                      staticClass: "flex mb-3 justify-between",
                    },
                    [
                      _c("span", { staticClass: "flex items-center" }, [
                        _c("span", {
                          staticClass:
                            "inline-block h-4 w-4 rounded-full mr-2 bg-white border-3 border-solid",
                          class: `border-${orderData.color}`,
                        }),
                        _c("span", { staticClass: "font-semibold" }, [
                          _vm._v(_vm._s(orderData.orderType)),
                        ]),
                      ]),
                      _c("span", [_vm._v(_vm._s(orderData.counts))]),
                    ]
                  )
                }),
                0
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/3 mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Customers" } },
            [
              _c(
                "template",
                { slot: "actions" },
                [_c("change-time-duration-dropdown")],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "no-body" }, slot: "no-body" },
                [
                  _c("vue-apex-charts", {
                    staticClass: "my-12",
                    attrs: {
                      type: "pie",
                      height: "334",
                      options: _vm.analyticsData.customersPie.chartOptions,
                      series: _vm.customersData.series,
                    },
                  }),
                  _c(
                    "ul",
                    { staticClass: "mb-1" },
                    _vm._l(
                      _vm.customersData.analyticsData,
                      function (customerData) {
                        return _c(
                          "li",
                          {
                            key: customerData.customerType,
                            staticClass:
                              "flex justify-between py-3 px-6 border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-b-0",
                          },
                          [
                            _c("span", { staticClass: "flex items-center" }, [
                              _c("span", {
                                staticClass:
                                  "inline-block h-3 w-3 rounded-full mr-2",
                                class: `bg-${customerData.color}`,
                              }),
                              _c("span", { staticClass: "font-semibold" }, [
                                _vm._v(_vm._s(customerData.customerType)),
                              ]),
                            ]),
                            _c("span", [_vm._v(_vm._s(customerData.counts))]),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Sales", subtitle: "Last 6 Months" } },
            [
              _c(
                "template",
                { slot: "actions" },
                [
                  _c("feather-icon", {
                    attrs: {
                      icon: "MoreVerticalIcon",
                      svgClasses: "w-6 h-6 text-grey",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "flex" }, [
                _c("span", { staticClass: "flex items-center" }, [
                  _c("div", {
                    staticClass: "h-3 w-3 rounded-full mr-2 bg-primary",
                  }),
                  _c("span", [_vm._v("Sales")]),
                ]),
                _c("span", { staticClass: "flex items-center ml-5" }, [
                  _c("div", {
                    staticClass: "h-3 w-3 rounded-full mr-2 bg-success",
                  }),
                  _c("span", [_vm._v("Visits")]),
                ]),
              ]),
              _c(
                "div",
                { attrs: { slot: "no-body-bottom" }, slot: "no-body-bottom" },
                [
                  _c("vue-apex-charts", {
                    attrs: {
                      type: "radar",
                      options: _vm.analyticsData.statisticsRadar.chartOptions,
                      series: _vm.salesRadar.series,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Support Tracker" } },
            [
              _c(
                "template",
                { slot: "actions" },
                [_c("change-time-duration-dropdown")],
                1
              ),
              _vm.supportTracker.analyticsData
                ? _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
                    _c("div", { staticClass: "vx-row text-center" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-col w-full lg:w-1/5 md:w-full sm:w-1/5 flex flex-col justify-between mb-4 lg:order-first md:order-last sm:order-first order-last",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "lg:ml-6 lg:mt-6 md:mt-0 md:ml-0 sm:ml-6 sm:mt-6",
                            },
                            [
                              _c("h1", { staticClass: "font-bold text-5xl" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.supportTracker.analyticsData.openTickets
                                  )
                                ),
                              ]),
                              _c("small", [_vm._v("Tickets")]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-col w-full lg:w-4/5 md:w-full sm:w-4/5 justify-center mx-auto lg:mt-0 md:mt-6 sm:mt-0 mt-6",
                        },
                        [
                          _c("vue-apex-charts", {
                            attrs: {
                              type: "radialBar",
                              height: "385",
                              options:
                                _vm.analyticsData.supportTrackerRadialBar
                                  .chartOptions,
                              series: _vm.supportTracker.series,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-row justify-between px-8 pb-4 mt-2",
                      },
                      _vm._l(
                        _vm.supportTracker.analyticsData.meta,
                        function (val, key) {
                          return _c(
                            "p",
                            { key: key, staticClass: "text-center" },
                            [
                              _c("span", { staticClass: "block" }, [
                                _vm._v(_vm._s(key)),
                              ]),
                              _c(
                                "span",
                                { staticClass: "text-2xl font-semibold" },
                                [_vm._v(_vm._s(val))]
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-2/3 mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Revenue" } },
            [
              _c(
                "template",
                { slot: "actions" },
                [
                  _c("feather-icon", {
                    attrs: {
                      icon: "SettingsIcon",
                      svgClasses: "w-6 h-6 text-grey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "p-6 pb-0",
                  attrs: { slot: "no-body" },
                  slot: "no-body",
                },
                [
                  _vm.revenueComparisonLine.analyticsData
                    ? _c("div", { staticClass: "flex" }, [
                        _c("div", { staticClass: "mr-6" }, [
                          _c("p", { staticClass: "mb-1 font-semibold" }, [
                            _vm._v("This Month"),
                          ]),
                          _c("p", { staticClass: "text-3xl text-success" }, [
                            _c("sup", { staticClass: "text-base mr-1" }, [
                              _vm._v("$"),
                            ]),
                            _vm._v(
                              _vm._s(
                                _vm.revenueComparisonLine.analyticsData.thisMonth.toLocaleString()
                              )
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _c("p", { staticClass: "mb-1 font-semibold" }, [
                            _vm._v("Last Month"),
                          ]),
                          _c("p", { staticClass: "text-3xl" }, [
                            _c("sup", { staticClass: "text-base mr-1" }, [
                              _vm._v("$"),
                            ]),
                            _vm._v(
                              _vm._s(
                                _vm.revenueComparisonLine.analyticsData.lastMonth.toLocaleString()
                              )
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _c("vue-apex-charts", {
                    attrs: {
                      type: "line",
                      height: "266",
                      options:
                        _vm.analyticsData.revenueComparisonLine.chartOptions,
                      series: _vm.revenueComparisonLine.series,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/3 mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Goal Overview" } },
            [
              _c(
                "template",
                { slot: "actions" },
                [
                  _c("feather-icon", {
                    attrs: {
                      icon: "HelpCircleIcon",
                      svgClasses: "w-6 h-6 text-grey",
                    },
                  }),
                ],
                1
              ),
              _c("template", { slot: "no-body" }, [
                _c(
                  "div",
                  { staticClass: "mt-10" },
                  [
                    _c("vue-apex-charts", {
                      attrs: {
                        type: "radialBar",
                        height: "240",
                        options:
                          _vm.analyticsData.goalOverviewRadialBar.chartOptions,
                        series: _vm.goalOverview.series,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm.goalOverview.analyticsData
                ? _c(
                    "div",
                    {
                      staticClass: "flex justify-between text-center mt-4",
                      attrs: { slot: "no-body-bottom" },
                      slot: "no-body-bottom",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-1/2 border border-solid d-theme-border-grey-light border-r-0 border-b-0 border-l-0",
                        },
                        [
                          _c("p", { staticClass: "mt-4" }, [
                            _vm._v("Completed"),
                          ]),
                          _c(
                            "p",
                            { staticClass: "mb-4 text-3xl font-semibold" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.goalOverview.analyticsData.completed.toLocaleString()
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-1/2 border border-solid d-theme-border-grey-light border-r-0 border-b-0",
                        },
                        [
                          _c("p", { staticClass: "mt-4" }, [
                            _vm._v("In Progress"),
                          ]),
                          _c(
                            "p",
                            { staticClass: "mb-4 text-3xl font-semibold" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.goalOverview.analyticsData.inProgress.toLocaleString()
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/2 mb-base" },
        [
          _c(
            "vx-card",
            [
              _c(
                "div",
                { staticClass: "vx-row flex-col-reverse lg:flex-row" },
                [
                  _vm.salesBarSession.analyticsData
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vx-col w-full lg:w-1/2 xl:w-1/2 flex flex-col justify-between",
                        },
                        [
                          _c("div", [
                            _c("h2", { staticClass: "mb-1 font-bold" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("k_formatter")(
                                    _vm.salesBarSession.analyticsData.session
                                  )
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "font-medium" }, [
                              _vm._v("Avg Sessions"),
                            ]),
                            _c(
                              "p",
                              { staticClass: "mt-2 text-xl font-medium" },
                              [
                                _c(
                                  "span",
                                  {
                                    class:
                                      _vm.salesBarSession.analyticsData
                                        .comparison.result >= 0
                                        ? "text-success"
                                        : "text-danger",
                                  },
                                  [
                                    _vm.salesBarSession.analyticsData.comparison
                                      .result > 0
                                      ? _c("span", [_vm._v("+")])
                                      : _vm._e(),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.salesBarSession.analyticsData
                                            .comparison.result
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("span", [_vm._v(" vs ")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.salesBarSession.analyticsData
                                        .comparison.str
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _c(
                            "vs-button",
                            {
                              staticClass: "shadow-md w-full lg:mt-0 mt-4",
                              attrs: {
                                "icon-pack": "feather",
                                icon: "icon-chevrons-right",
                                "icon-after": "",
                              },
                            },
                            [_vm._v("View Details")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col w-full lg:w-1/2 xl:w-1/2 flex flex-col lg:mb-0 mb-base",
                    },
                    [
                      _c("change-time-duration-dropdown", {
                        staticClass: "self-end",
                      }),
                      _vm.salesBarSession.series
                        ? _c("vue-apex-charts", {
                            attrs: {
                              type: "bar",
                              height: "200",
                              options: _vm.analyticsData.salesBar.chartOptions,
                              series: _vm.salesBarSession.series,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c("vs-divider", { staticClass: "my-6" }),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-1/2 mb-3" },
                  [
                    _c("small", [_vm._v("Goal: $100000")]),
                    _c("vs-progress", {
                      staticClass: "block mt-1",
                      attrs: { percent: 50, color: "primary" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-1/2 mb-3" },
                  [
                    _c("small", [_vm._v("Users: 100K")]),
                    _c("vs-progress", {
                      staticClass: "block mt-1",
                      attrs: { percent: 60, color: "warning" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-1/2 mb-3" },
                  [
                    _c("small", [_vm._v("Retention: 90%")]),
                    _c("vs-progress", {
                      staticClass: "block mt-1",
                      attrs: { percent: 70, color: "danger" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-1/2 mb-3" },
                  [
                    _c("small", [_vm._v("Duration: 1yr")]),
                    _c("vs-progress", {
                      staticClass: "block mt-1",
                      attrs: { percent: 90, color: "success" },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/2 mb-base" },
        [
          _c(
            "vx-card",
            { staticClass: "overflow-hidden" },
            [
              _c("template", { slot: "no-body" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex justify-between items-center p-6 border border-solid d-theme-border-grey-light border-r-0 border-l-0 border-t-0",
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("p", [
                          _c("span", { staticClass: "font-semibold" }, [
                            _vm._v(
                              _vm._s(_vm.todoToday.numComletedTasks) +
                                " task completed"
                            ),
                          ]),
                          _vm._v(" out of " + _vm._s(_vm.todoToday.totalTasks)),
                        ]),
                        _c("vs-progress", {
                          attrs: { percent: 20, color: "primary" },
                        }),
                      ],
                      1
                    ),
                    _c("span", [_vm._v(_vm._s(_vm.todoToday.date))]),
                  ]
                ),
                _c(
                  "ul",
                  { staticClass: "tasks-today-container" },
                  _vm._l(_vm.todoToday.tasksToday, function (todo) {
                    return _c(
                      "li",
                      {
                        key: todo.id,
                        staticClass: "px-6 py-4 tasks-today__task",
                      },
                      [
                        _c("div", { staticClass: "vx-row" }, [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full sm:w-auto" },
                            [
                              _c(
                                "p",
                                { staticClass: "font-semibold text-lg" },
                                [_vm._v(_vm._s(todo.task))]
                              ),
                              _c("small", [_vm._v(_vm._s(todo.date))]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "tasks-today__actions vx-col w-full sm:w-auto ml-auto mt-2 sm:mt-0",
                            },
                            [
                              _c("vs-button", {
                                attrs: {
                                  radius: "",
                                  color: "primary",
                                  type: "border",
                                  "icon-pack": "feather",
                                  icon: "icon-check",
                                  size: "small",
                                },
                              }),
                              _c("vs-button", {
                                staticClass: "ml-3",
                                attrs: {
                                  radius: "",
                                  color: "primary",
                                  type: "border",
                                  "icon-pack": "feather",
                                  icon: "icon-edit-2",
                                  size: "small",
                                },
                              }),
                              _c("vs-button", {
                                staticClass: "ml-3",
                                attrs: {
                                  radius: "",
                                  color: "primary",
                                  type: "border",
                                  "icon-pack": "feather",
                                  icon: "icon-trash",
                                  size: "small",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-2/3 lg:w-3/4" },
        [
          _c(
            "vx-card",
            { staticClass: "mb-base", attrs: { title: "Sales" } },
            [
              _c(
                "template",
                { slot: "actions" },
                [
                  _c("feather-icon", {
                    attrs: {
                      icon: "SettingsIcon",
                      svgClasses: "w-6 h-6 text-grey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "p-6 pb-0",
                  attrs: { slot: "no-body" },
                  slot: "no-body",
                },
                [
                  _c("vue-apex-charts", {
                    attrs: {
                      type: "line",
                      height: "266",
                      options: _vm.analyticsData.salesLine.chartOptions,
                      series: _vm.salesLine.series,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/3 lg:w-1/4 xl:w-1/4" },
        [
          _c(
            "vx-card",
            [
              Object.entries(_vm.funding).length
                ? _c("template", { slot: "no-body" }, [
                    _c(
                      "div",
                      { staticClass: "p-8 clearfix" },
                      [
                        _c("div", [
                          _c("h1", [
                            _c("sup", { staticClass: "text-lg" }, [
                              _vm._v("$"),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.funding.currBalance.toLocaleString())
                              ),
                            ]),
                          ]),
                          _c("small", [
                            _c("span", { staticClass: "text-grey" }, [
                              _vm._v("Deposits: "),
                            ]),
                            _c("span", [_vm._v("$")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.funding.depostis.toLocaleString())
                              ),
                            ]),
                          ]),
                        ]),
                        _c(
                          "p",
                          {
                            staticClass: "mt-2 mb-8 text-xl font-medium",
                            class:
                              _vm.funding.comparison.resultPerc >= 0
                                ? "text-success"
                                : "text-danger",
                          },
                          [
                            _vm.funding.comparison.resultPerc > 0
                              ? _c("span", [_vm._v("+")])
                              : _vm._e(),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.funding.comparison.resultPerc) + "%"
                              ),
                            ]),
                            _c("span", { staticClass: "ml-1" }, [
                              _vm._v(
                                "($" +
                                  _vm._s(_vm.funding.comparison.pastData) +
                                  ")"
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "vs-button",
                          {
                            staticClass: "shadow-md w-full",
                            attrs: {
                              "icon-pack": "feather",
                              icon: "icon-chevrons-right",
                              "icon-after": "",
                            },
                          },
                          [_vm._v("Add Funds")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "p-8 border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-b-0",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("small", [
                              _vm._v(
                                "Earned: $" +
                                  _vm._s(_vm.funding.meta.earned.val)
                              ),
                            ]),
                            _c("vs-progress", {
                              attrs: {
                                percent: _vm.funding.meta.earned.progress,
                                color: "success",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("small", [
                              _vm._v(
                                "Duration: " +
                                  _vm._s(_vm.funding.meta.duration.val)
                              ),
                            ]),
                            _c("vs-progress", {
                              attrs: {
                                percent: _vm.funding.meta.duration.progress,
                                color: "warning",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
        [
          _c(
            "vx-card",
            { staticClass: "mt-base", attrs: { title: "Browser Statistics" } },
            _vm._l(_vm.browserStatistics, function (browser, index) {
              return _c(
                "div",
                { key: browser.id, class: { "mt-4": index } },
                [
                  _c("div", { staticClass: "flex justify-between" }, [
                    _c("div", { staticClass: "flex flex-col" }, [
                      _c("span", { staticClass: "mb-1" }, [
                        _vm._v(_vm._s(browser.name)),
                      ]),
                      _c("h4", [_vm._v(_vm._s(browser.ratio) + "%")]),
                    ]),
                    _c("div", { staticClass: "flex flex-col text-right" }, [
                      _c(
                        "span",
                        { staticClass: "flex -mr-1" },
                        [
                          _c("span", { staticClass: "mr-1" }, [
                            _vm._v(_vm._s(browser.comparedResult)),
                          ]),
                          _c("feather-icon", {
                            attrs: {
                              icon:
                                browser.comparedResult < 0
                                  ? "ArrowDownIcon"
                                  : "ArrowUpIcon",
                              svgClasses: [
                                browser.comparedResult < 0
                                  ? "text-danger"
                                  : "text-success",
                                "stroke-current h-4 w-4 mb-1 mr-1",
                              ],
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "text-grey" }, [
                        _vm._v(_vm._s(_vm._f("time")(browser.time, true))),
                      ]),
                    ]),
                  ]),
                  _c("vs-progress", { attrs: { percent: browser.ratio } }),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-2/3" },
        [
          _c(
            "vx-card",
            { staticClass: "mt-base", attrs: { title: "Client Retention" } },
            [
              _c("div", { staticClass: "flex items-center mb-3" }, [
                _c("span", { staticClass: "flex items-center" }, [
                  _c("div", {
                    staticClass: "h-3 w-3 rounded-full mr-1 bg-warning",
                  }),
                  _c("span", [_vm._v("New Clients")]),
                ]),
                _c("span", { staticClass: "flex items-center ml-4" }, [
                  _c("div", {
                    staticClass: "h-3 w-3 rounded-full mr-1 bg-danger",
                  }),
                  _c("span", [_vm._v("Retained Clients")]),
                ]),
              ]),
              _c("vue-apex-charts", {
                attrs: {
                  type: "bar",
                  height: "266",
                  options: _vm.analyticsData.clientRetentionBar.chartOptions,
                  series: _vm.clientRetentionBar.series,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }